import { graphql, Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import React, { FC } from 'react';

import App from '../components/App';
import CategoryTitle from '../components/CategoryTitle';

const TagsPage: FC<{ data: any; group: any }> = ({
  data: {
    allMarkdownRemark: { group },
  },
}) => (
  <App>
    <CategoryTitle category="Tags" />
    <div className="m-auto p-24">
      <ul className="tags">
        {group.map((tag: { fieldValue: string; totalCount: number }) => (
          <li key={tag.fieldValue} className=" p-2 text-center">
            <Link
              className=" hover:text-white  bg-secondary capitalize text-sm rounded m-3 p-2 text-white"
              to={`/tags/${kebabCase(tag.fieldValue)}/`}
            >
              {tag.fieldValue} ({tag.totalCount})
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </App>
);

export default TagsPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tag) {
        fieldValue
        totalCount
      }
    }
  }
`;
